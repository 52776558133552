import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense
      fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <GuestGuard>
          <Login />
        </GuestGuard>
      ),
      children: [
        {
          index: true,
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'app',
          element: (
            <RoleBasedGuard
              accessibleRoles={['admin', 'sales', 'content writer', 'investor']}
            >
              {' '}
              <GeneralApp />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'ecommerce',
          element: (
            <RoleBasedGuard accessibleRoles={['admin', 'sales', 'investor']}>
              <GeneralEcommerce />
            </RoleBasedGuard>
          ),
        },
        { path: 'analytics', element: <GeneralAnalytics /> },
        {
          path: 'company',
          element: (
            <RoleBasedGuard accessibleRoles={['admin', 'sales', 'investor']}>
              <GeneralBanking />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'companies',
          element: (
            <RoleBasedGuard accessibleRoles={['admin', 'sales', 'investor']}>
              {' '}
              <Companies />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'companies/new',
          element: (
            <RoleBasedGuard accessibleRoles={['admin', 'sales']}>
              <NewCompany />{' '}
            </RoleBasedGuard>
          ),
        },

        // { path: 'booking', element: <GeneralBooking /> },

        {
          path: 'cashout',
          children: [
            {
              path: 'users',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <UserCashouts />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'fawry',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <FawryCashouts />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'suppliers',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <SupplierCashouts />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'payroll',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <PayRoll />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'transactions',
              element: (
                <RoleBasedGuard accessibleRoles={['admin']}>
                  <Transaction />
                </RoleBasedGuard>
              ),
            },
          ],
        },

        {
          path: 'messages',
          children: [
            {
              path: 'referrals',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'sales']}>
                  <Referrals />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'demos',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'sales']}>
                  <Demos />{' '}
                </RoleBasedGuard>
              ),
            },
          ],
        },
        // {
        //   path: 'e-commerce',
        //   children: [
        //     { element: <Navigate to="/dashboard/e-commerce/shop" replace />, index: true },
        //     { path: 'shop', element: <EcommerceShop /> },
        //     { path: 'product/:name', element: <EcommerceProductDetails /> },
        //     { path: 'list', element: <EcommerceProductList /> },
        //     { path: 'product/new', element: <EcommerceProductCreate /> },
        //     { path: 'product/:name/edit', element: <EcommerceProductCreate /> },
        //     { path: 'checkout', element: <EcommerceCheckout /> },
        //     { path: 'invoice', element: <EcommerceInvoice /> },
        //   ],
        // },

        {
          path: 'user',
          children: [
            {
              element: <Navigate to="/dashboard/user/profile" replace />,
              index: true,
            },
            { path: 'profile', element: <UserProfile /> },
            { path: 'cards', element: <UserCards /> },
            {
              path: 'list',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'sales']}>
                  <UserList />{' '}
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'sales']}>
                  <UserCreate />
                </RoleBasedGuard>
              ),
            },
            {
              path: ':name/edit',
              element: (
                <RoleBasedGuard accessibleRoles={['admin', 'sales']}>
                  <UserCreate />{' '}
                </RoleBasedGuard>
              ),
            },
            { path: 'account', element: <UserAccount /> },
          ],
        },
        {
          path: 'blog',
          children: [
            {
              element: <Navigate to="/dashboard/blog/posts" replace />,
              index: true,
            },
            {
              path: 'posts',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'admin',
                    'sales',
                    'content writer',
                    'investor',
                  ]}
                >
                  <BlogPosts />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'post/:title',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'admin',
                    'sales',
                    'content writer',
                    'investor',
                  ]}
                >
                  <BlogPost />
                </RoleBasedGuard>
              ),
            },
            {
              path: 'new-post',
              element: (
                <RoleBasedGuard
                  accessibleRoles={[
                    'admin',
                    'sales',
                    'content writer',
                    'investor',
                  ]}
                >
                  <BlogNewPost />
                </RoleBasedGuard>
              ),
            },
          ],
        },
        {
          path: 'mail',
          children: [
            {
              element: <Navigate to="/dashboard/mail/all" replace />,
              index: true,
            },
            { path: 'label/:customLabel', element: <Mail /> },
            { path: 'label/:customLabel/:mailId', element: <Mail /> },
            { path: ':systemLabel', element: <Mail /> },
            { path: ':systemLabel/:mailId', element: <Mail /> },
          ],
        },
        {
          path: 'chat',
          children: [
            { element: <Chat />, index: true },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> },
          ],
        },
        {
          path: 'calendar',
          element: (
            <RoleBasedGuard
              accessibleRoles={['admin', 'sales', 'content writer', 'investor']}
            >
              <Calendar />{' '}
            </RoleBasedGuard>
          ),
        },
        { path: 'kanban', element: <Kanban /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: 'pricing', element: <Pricing /> },
        { path: 'payment', element: <Payment /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { element: <HomePage />, index: true },
        { path: 'about-us', element: <About /> },
        { path: 'contact-us', element: <Contact /> },
        { path: 'faqs', element: <Faqs /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(
  lazy(() => import('../pages/auth/ResetPassword'))
);
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
// Dashboard
const GeneralApp = Loadable(
  lazy(() => import('../pages/dashboard/GeneralApp'))
);
const GeneralEcommerce = Loadable(
  lazy(() => import('../pages/dashboard/GeneralEcommerce'))
);
const GeneralAnalytics = Loadable(
  lazy(() => import('../pages/dashboard/GeneralAnalytics'))
);
const GeneralBanking = Loadable(
  lazy(() => import('../pages/dashboard/CompanyPages/GeneralBanking'))
);
const Companies = Loadable(
  lazy(() => import('../pages/dashboard/CompanyPages/Companies'))
);
const NewCompany = Loadable(
  lazy(() => import('../pages/dashboard/CompanyPages/NewCompany'))
);

// cashouts
const UserCashouts = Loadable(
  lazy(() => import('../pages/dashboard/cashouts/UserCashouts'))
);
const FawryCashouts = Loadable(
  lazy(() => import('../pages/dashboard/cashouts/FawryCashouts'))
);
const SupplierCashouts = Loadable(
  lazy(() => import('../pages/dashboard/cashouts/SuppliersCashouts'))
);
const PayRoll = Loadable(
  lazy(() => import('../pages/dashboard/cashouts/payrolls/Payroll'))
);
const Transaction = Loadable(
  lazy(() => import('../pages/dashboard/cashouts/Transaction'))
);

// messages
const Referrals = Loadable(
  lazy(() => import('../pages/dashboard/Referls/Referral'))
);
const Demos = Loadable(lazy(() => import('../pages/dashboard/Demo/Demo')));
// ------

// const GeneralBooking = Loadable(
//   lazy(() => import('../pages/dashboard/GeneralBooking'))
// );
// const EcommerceShop = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceShop'))
// );
// const EcommerceProductDetails = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductDetails'))
// );
// const EcommerceProductList = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductList'))
// );
// const EcommerceProductCreate = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceProductCreate'))
// );
// const EcommerceCheckout = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceCheckout'))
// );
// const EcommerceInvoice = Loadable(
//   lazy(() => import('../pages/dashboard/EcommerceInvoice'))
// );
const BlogPosts = Loadable(lazy(() => import('../pages/dashboard/BlogPosts')));
const BlogPost = Loadable(lazy(() => import('../pages/dashboard/BlogPost')));
const BlogNewPost = Loadable(
  lazy(() => import('../pages/dashboard/BlogNewPost'))
);
const UserProfile = Loadable(
  lazy(() => import('../pages/dashboard/UserProfile'))
);
const UserCards = Loadable(lazy(() => import('../pages/dashboard/UserCards')));
const UserList = Loadable(lazy(() => import('../pages/dashboard/UserList')));
const UserAccount = Loadable(
  lazy(() => import('../pages/dashboard/UserAccount'))
);
const UserCreate = Loadable(
  lazy(() => import('../pages/dashboard/UserCreate'))
);
const Chat = Loadable(lazy(() => import('../pages/dashboard/Chat')));
const Mail = Loadable(lazy(() => import('../pages/dashboard/Mail')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));
const Kanban = Loadable(lazy(() => import('../pages/dashboard/Kanban')));
// Main
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const Contact = Loadable(lazy(() => import('../pages/Contact')));
const Faqs = Loadable(lazy(() => import('../pages/Faqs')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Pricing = Loadable(lazy(() => import('../pages/Pricing')));
const Payment = Loadable(lazy(() => import('../pages/Payment')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
